<template>
  <el-form ref="form" :model="form" :rules="rules">
    <div class="form-item">
      <div class="form-label">
        <label for="firstname">First name</label>
      </div>
      <div class="form-cell">
        <el-form-item prop="firstname">
          <el-input
            v-model="form.firstname"
            placeholder="Jane"
            prop="firstname"
          />
        </el-form-item>
      </div>
    </div>
    <div class="form-item">
      <div class="form-label">
        <label for="lastname">Last name</label>
      </div>
      <div class="form-cell">
        <el-form-item prop="lastname">
          <el-input
            v-model="form.lastname"
            placeholder="Diaz"
            prop="lastname"
          />
        </el-form-item>
      </div>
    </div>
    <div class="form-item">
      <div class="form-label">
        <label for="firstname">Email</label>
      </div>
      <div class="form-cell">
        <el-form-item prop="email">
          <el-input
            v-model="form.email"
            placeholder="jane@example.com"
          ></el-input>
        </el-form-item>
      </div>
    </div>
    <div class="form-item">
      <div class="form-label">
        <label for="firstname">Phone number</label>
      </div>
      <div class="form-cell">
        <el-form-item prop="phonenumber">
          <el-input
            v-model="form.phonenumber"
            placeholder="+XX XXX-XXXX-XXXX"
          />
        </el-form-item>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        <label for="firstname">Country / Area</label>
      </div>
      <div class="form-cell">
        <el-form-item prop="region">
          <el-select
            v-model="form.region"
            placeholder="Country / Area"
            @change="handleChangeCountry"
            @visible-change="handleSelectDropdownIsShow"
          >
            <el-option
              :label="item.name"
              :key="item.code"
              :value="item.code"
              v-for="item in countryListAllIsoData"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        <label for="firstname">Payments volume</label>
        <p class="info">
          How much money does your business process online each month?
        </p>
      </div>
      <div class="form-cell">
        <el-form-item>
          <el-select
            v-model="form.payments"
            placeholder="Select amonthly amount"
            @visible-change="handleSelectDropdownIsShow"
          >
            <el-option
              :label="item"
              :key="item"
              :value="item"
              v-for="item in rangePaymentData"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        <label for="firstname">Number of employees</label>
      </div>
      <div class="form-cell">
        <el-form-item>
          <el-select
            v-model="form.employees"
            placeholder="Select range of employees"
            @visible-change="handleSelectDropdownIsShow"
          >
            <el-option
              :label="item"
              :key="index"
              :value="item"
              v-for="(item, index) in rangeEmployeesData"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        <label for="firstname">Interests</label>
        <p class="info">
          How would you describe your business or area of interest. Selet all
          that apply.
        </p>
      </div>
      <div class="form-cell">
        <el-form-item>
          <el-checkbox-group v-model="form.interests">
            <el-checkbox
              v-for="item in interestsList"
              :key="item.name"
              :value="item.value"
              :label="item.name"
            />
          </el-checkbox-group>
        </el-form-item>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        <label for="firstname">Anything else?</label>
        <p class="info">Optional</p>
      </div>
      <div class="form-cell">
        <el-input
          v-model="form.otherneeds"
          class="form-textarea"
          type="textarea"
          :rows="6"
          placeholder="Any other needs"
        ></el-input>
      </div>
    </div>

    <div class="form-btn">
      <button class="btn-primary" @click.prevent="submitForm">Submit</button>
    </div>
  </el-form>
</template>

<script>
import countryListAllIsoData from "../kits/variousCountryListFormats";
import { formatThousand } from "../kits/tools";

const currencySymbol = {
  default: "$",
  AE: "AED",
  HK: "HK$",
};

// TODO: 更多国家的货币及符号，参考 https://stripe.com/en-hk/use-cases/crypto#request-invite
const paymentNums = {
  default: [
    [0, 50000],
    [50000, 150000],
    [150000, 1000000],
    [1000000, 5000000],
    [5000000, "max"],
  ],
  US: [
    [0, 50000],
    [50000, 100000],
    [100000, 1000000],
    [1000000, 10000000],
    [10000000, "max"],
  ],
  HK: [
    [0, 200000],
    [200000, 500000],
    [500000, 3000000],
    [3000000, 30000000],
    [30000000, "max"],
  ],
};

export default {
  name: "FormForCoop",
  watch: {},
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phonenumber: "",
        employees: "",
        payments: "",
        interests: [],
        region: "",
        otherneeds: "",
      },
      rules: {
        firstname: [
          { required: true, message: "请输入正确的名字", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        lastname: [
          { required: true, message: "请输入正确的名字", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        phonenumber: [
          { required: true, message: "请输入正确的名字", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      countryListAllIsoData: [],
      rangePaymentData: [],
      rangeEmployeesData: ["1-99", "100-999", "1000-4999", "5000+"],
      interestsList: [
        {
          name: "Acceping and paying out fiat",
          value: "1",
        },
        {
          name: "Accepting andd paying out crypto",
          value: "2",
        },
        {
          name: "NFT marketplaces",
          value: "3",
        },
        {
          name: "NFT sales",
          value: "4",
        },
        {
          name: "Crypto exchanges",
          value: "5",
        },
        {
          name: "Fraud management",
          value: "6",
        },
        {
          name: "Identity verificcation",
          value: "7",
        },
        {
          name: "DeFi",
          value: "8",
        },
        {
          name: "DAO",
          value: "9",
        },
        {
          name: "Other",
          value: "10",
        },
      ],
    };
  },
  created() {
    this.countryListAllIsoData = countryListAllIsoData;
  },
  methods: {
    // 下拉菜单是否显示
    handleSelectDropdownIsShow(status) {
      if (status) {
        document.querySelector(".layer-dialog").style.overflow = "hidden";
      } else {
        document.querySelector(".layer-dialog").style.overflow = "auto";
      }
    },
    // 渲染icon
    renderIcon() {
      const { icon, type } = this.props;
      const hasIcon = icon || type === "success" || type === "fail";

      if (hasIcon) {
        return <img name={icon || type} class={"icon"} />;
      }
    },

    handleChangeCountry(countryCode) {
      const Symbol = currencySymbol[countryCode] || currencySymbol.default;
      const Nums = paymentNums[countryCode] || paymentNums.default;
      // TODO:i18n 多语言
      const newData = Nums.map((item, index) => {
        if (index === 0) {
          return `Less than ${Symbol}${formatThousand(item[1])}`;
        } else if (index === Nums.length - 1) {
          return `More than ${Symbol}${formatThousand(item[0])}`;
        } else {
          return `${Symbol}${formatThousand(
            item[0]
          )} to ${Symbol}${formatThousand(item[1])}`;
        }
      });

      this.rangePaymentData = ["None, just getting started", ...newData];
      this.form.payments = "";
    },
    // 复选框逻辑
    submitForm() {
      // checkbox group 替换

      this.$emit("submit", this.form);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@contWidth: 702px;
.layer-confirm {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.layer-header {
  height: 50px;
  top: 32%;
  position: fixed;
  width: @contWidth;
  left: 50%;
  z-index: 10;
  margin-left: -(@contWidth / 2);
  .layer-icon {
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
}
.layer-body {
  background: #fff;
  border-radius: 32px;
  position: fixed;
  width: @contWidth;
  left: 50%;
  top: 42%;
  z-index: 9;
  margin-left: -(@contWidth / 2);
  min-height: 100px;
  box-sizing: border-box;
  padding: 100px 30px 60px;
  .body-inner {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--sapphire);
  }
  .btn-cont {
    text-align: center;
    padding-top: 40px;
  }
}
.layer-mask {
  background-color: var(--navy-blue);
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}
</style>
