<template>
  <div>
    <!-- screen1 -->
    <div class="focus-header contact-header">
      <div class="inner-cont">
        <div class="header-cont1">
          <h1>CONTACT US</h1>
          <h2>Whenever you have something to say.</h2>
          <section class="contact-us">
            <div>
              <dl>
                <dt>
                  <img
                    src="@/assets/images/icon-face-link-email-2.svg"
                    alt=""
                  />
                </dt>
                <dd>operations@fatpay.xyz</dd>
              </dl>
              <dl>
                <dt>
                  <img
                    src="@/assets/images/icon-face-link-telephone.svg"
                    alt=""
                  />
                </dt>
                <dd>+370 (5) 214 0247</dd>
              </dl>
            </div>
          </section>
        </div>
        <div class="header-bg" />
      </div>
    </div>
    <div class="page-content contact-content style-light">
      <div class="inner-cont">
        <div class="page-title">
          <strong>FIND US</strong>
          <h2>Offices</h2>
        </div>
        <div class="map-cont">
          <!-- maps -->
          <component :is="mapCompName" />
          <!-- <image-map /> -->
        </div>
      </div>
    </div>
    <!-- footer -->
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageFooter from "@/components/PageFooter.vue";
import ImageMap from "@/components/ImageMap.vue";
import ImageMapMobile from "@/components/ImageMap/ImageMapMobile.vue";
import { deviceInfo } from "@/kits/tools";

export default {
  name: "ContactView",
  data() {
    return {
      mapCompName: "ImageMap",
    };
  },
  components: {
    PageFooter,
    ImageMap,
    ImageMapMobile,
  },
  created() {
    if (deviceInfo.isMobile) {
      this.mapCompName = "ImageMapMobile";
    }
  },
};
</script>

<style scoped lang="less">
.focus-header {
  background-image: url("@/assets/images/fat-cover-photo-bg-contact.jpg");
  .inner-cont {
    overflow: hidden;
  }
  .header-cont1 {
    min-height: 900px;
  }
  .header-bg {
    background: url("@/assets/images/coverphoto-contact.png") no-repeat 100% 0;
    position: absolute;
    width: 1340px;
    height: 960px;
    top: 120px;
    left: 60%;
    margin-left: -400px;
    display: block;
    z-index: 1;
    background-size: auto 960px;
  }
  h1 {
    position: absolute;
    top: 312px;
    left: 0;
  }
  h2 {
    position: absolute;
    top: 452px;
    left: 0;
    width: 524px;
    height: 32px;
    font-family: "Poppins-Light";
    font-size: 18px;
    font-weight: 300;
    line-height: 1.78;
    letter-spacing: 0.5px;
    color: var(--light-sky-blue);
  }
  .contact-us {
    position: absolute;
    top: 620px;
    left: 0;
    width: 613px;
    height: 220px;
    border-radius: 32px;
    background-color: var(--bg-content-normal);
    > div {
      padding-left: 90px;
      padding-right: 90px;
      padding-top: 22.4px;
    }
    dl {
      display: flex;
      align-items: center;
      position: relative;
      height: 36px;
      padding-top: 23px;
      padding-bottom: 7.5px;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        width: 433px;
        height: 0.5px;
        opacity: 0.4;
        background-color: var(--sapphire);
      }
    }
    dd {
      font-family: "Poppins-Light";
      font-size: 18px;
      font-weight: 300;
      line-height: 2;
      letter-spacing: 0.38px;
      color: var(--sapphire);
      margin-left: 8px;
    }
  }
}
.map-cont {
  padding-top: 160px;
}
.svgmap {
  width: 100%;
  margin-top: 160px;
  height: 900px;
}
.contact-content .inner-cont {
  padding: 0;
}
@media screen and (max-width: 1060px) {
  .map-cont {
    overflow-y: hidden;
  }
}
</style>
