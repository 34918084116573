<template>
  <div>
    <!-- screen1 -->
    <div class="focus-header career-header">
      <div class="inner-cont">
        <div class="header-cont1">
          <h1>JOIN US NOW !</h1>
          <div class="base-cont">
            <p>
              FaTPay is a tech-startup which is dedicated to continuously
              building and improve the infrastructure of crypto world.
            </p>
            <p>
              If you are looking to be a member of the most imaginative team in
              the blockchain industry and contribute to the crypto era, JOIN US
              NOW !
            </p>
          </div>
        </div>
        <div class="header-bg" />
      </div>
    </div>
    <div class="page-content career-content style-dark">
      <div class="inner-cont">
        <div class="page-title">
          <strong>JOIN US</strong>
          <h2>We are<br />awaiting your<br />email</h2>
        </div>
        <section class="contact-us">
          <div>
            <dl>
              <dt>
                <img src="@/assets/images/icon-face-link-email-2.svg" alt="" />
              </dt>
              <dd>{{ EmailForJD }}</dd>
            </dl>
          </div>
          <img src="@/assets/images/invalid-name.png" alt="" />
          <div class="btn-box">
            <button class="btn-primary" @click="handleSendEmail">
              Send Now
            </button>
          </div>
        </section>
      </div>
    </div>
    <div class="page-content career-content style-light">
      <div class="inner-cont">
        <div class="page-title">
          <strong>JOIN US</strong>
          <h2>Opportunities</h2>
        </div>
        <lazy-component>
          <!-- TODO:竖线 -->
          <div class="career-list-warp">
            <ul class="career-list">
              <li
                v-for="(item, index) in JDList"
                :key="index"
                @click="handleShowJDDetail(index)"
              >
                <div class="title">{{ item.mainTitle }}</div>
                <div class="subtitle">
                  {{ item.region }} / {{ item.workTime }}
                </div>
                <div class="date">{{ item.pubTime }}</div>
                <div class="link">
                  <b>Details</b><i class="el-icon-arrow-right"></i>
                </div>
              </li>
            </ul>
          </div>
        </lazy-component>
      </div>
    </div>
    <!-- footer -->
    <page-footer></page-footer>
    <fat-dialog :isShow="isJDShow" :onClose="onJDClose"
      ><jd-detail :data="JdData" />
      <div class="btn-cont">
        <button class="btn-primary" @click="handleSubmitResume">
          Submit resume
        </button>
      </div>
    </fat-dialog>
  </div>
</template>

<script>
import PageFooter from "@/components/PageFooter.vue";
import FatDialog from "@/components/FatDialog.vue";
import JdDetail from "@/components/JdDetail.vue";

const JdData = [
  {
    mainTitle: "Fiat Channel Lead",
    region: "Singapore",
    workTime: "Fulltime",
    pubTime: "March 21, 2022",
    detail: [
      {
        title: "Responsibilities",
        data: [
          "Proactively reach out to new potential global/local partners, knowing the whole business picture to negotiate the business terms effectively and successfully onboard the partners",
          "Own the relationship with Fiat global/local partners, negotiate business terms, and onboard appropriate channels",
          "Maintain strong relationships with banks and payment partners as well as oversee regulatory and licensing issues",
          "Manage Fiat operations for the region, ensuring a consistent and excellent on/off ramp service",
          "Grow and lead a high-performing team, including shaping the fiat strategy globally and defining hiring needs in the market",
          "Lead local fiat/new user growth and business development activities",
          "Connect with the local community monitoring industry/competitor trends to optimise: the product, marketing, and operations strategies",
        ],
      },
      {
        title: "Requirements",
        data: [
          "5 years+ of proven success in a similar role leading business negotiations in ideally from a payments or financial institution",
          "Data-savvy, understand basic metrics of marketing and operations",
          "Demonstrated ability to work as a team player",
          "Problem solver and results-driven a passion for crypto and digital assets",
          "Fluency in English / Lithuanian",
        ],
      },
    ],
  },
  {
    mainTitle: "Social Media Specialist",
    region: "Remote",
    workTime: "Fulltime",
    pubTime: "March 15, 2022",
    detail: [
      {
        title: "Responsibilities",
        data: [
          "Follow up the product update to schedule official social media calendar",
          "Work with the internal team and external partners to identify storytelling opportunities from the field and produce eye-catching copywriting and visual content",
          "Grow user base on different social media platforms and local channels and increase the awareness of the our brand in fun, interactive and informative ways",
          "Organize and implement the social media campaigns according to our marketing calendar and through collaborating with other brands Research and build targeted media influencer lists and make connections with them",
        ],
      },
      {
        title: "Requirements",
        data: [
          "Bachelor's/Master's degree in Communications, Journalism, marketing or equivalent industry experience",
          "Advanced user of social media like Discord/Twitter/Youtube/Facebook/Instagram, etc. is preferred",
          "Great passion for the cryptocurrency industry. Crypto gaming and DAO user is preferred. ",
          "Solutions focused, resourceful and strong sense of initiative",
          "Business English proficiency",
          "Adapt to flexible working hours and remote collaboration",
        ],
      },
    ],
  },
  {
    mainTitle: "Blockchain Backend System Developer",
    region: "Hong Kong",
    workTime: "Fulltime",
    pubTime: "March 10, 2022",
    detail: [
      {
        title: "Responsibilities",
        data: [
          "Join the full life cycle of software development, including requirement gathering, designing, coding, testing and deployment",
          "Partner with QA team to improve the effectiveness of trading models",
          "Set up and maintain trading system infrastructure, with real position and orders",
        ],
      },
      {
        title: "Requirements",
        data: [
          "Proved track of record for great learning skills, love to solve challenging problems",
          "Familiar with Java or Go programing language",
          "Familar with Kubernetes and DevOps are great bonus",
          "Familiar with network and multi-thread programming, design pattern, and middleware",
          "Experience with Linux/Unix, network and server side application development",
          "Familiar with cryptography, or multi-party computing, or distributed computing theory or blockchain development especially Ethereum code is big bonus",
        ],
      },
    ],
  },
  {
    mainTitle: "Compliance Manager",
    region: "Vilnius",
    workTime: "Fulltime",
    pubTime: "March 10, 2022",
    detail: [
      {
        title: "Responsibilities",
        data: [
          "Development and management of Lithuania’s compliance policies and processes",
          "Ensure ongoing monitoring and reporting",
          "Coordinating various foreign regulatory filings",
          "Focusing on internal compliance policy review, including ongoing assessments, assisting with compliance review, internal audit reviews and ongoing or ad hoc reviews",
          "Assisting in the development of training materials",
          "Providing statistics on monitoring completion as needed",
          "Making recommendations for enhancing the firm’s systems, controls and processes",
        ],
      },
      {
        title: "Requirements",
        data: [
          "Proven interest and/or experience in the FinTech sector",
          "At least a Bachelor's degree with 5+ years of experience in a risk, fraud, compliance, AML or regulatory function, experience working with any regulated financial services business in legal or compliance roles preferred",
          "Professional certification in compliance risk management would be beneficial",
          "Ability to work independently in a fast-paced, global organization maintaining close contact with relevant teams across multiple locations",
          "Excellent problem solving skills and ability to provide useful and efficient counsel",
          "Good understanding in performing risk assessments, periodic/trigger reviews and enhanced due diligence",
          "Proficiency in compliance software",
        ],
      },
    ],
  },
];

export default {
  name: "ContactView",
  data() {
    return {
      EmailForJD: "operations@fatpay.xyz",
      isJDShow: false,
      JDList: [],
      JDSelectedId: 0,
      JdData: {
        mainTitle: "",
        region: "",
        workTime: "",
        pubTime: "",
        detail: [],
      },
    };
  },
  components: {
    PageFooter,
    FatDialog,
    JdDetail,
  },
  mounted() {
    this.getJDList();
  },
  methods: {
    handleSendEmail() {
      window.open(`mailto:${this.EmailForJD}`);
    },
    handleShowJDDetail(JDNum) {
      this.isJDShow = true;
      this.JdData = this.JDList[JDNum];
    },
    onJDClose() {
      this.isJDShow = false;
    },
    handleSubmitResume() {
      this.handleSendEmail();
      this.onJDClose();
    },
    getJDList() {
      this.JDList = JdData;
    },
  },
};
</script>

<style scoped lang="less">
.focus-header {
  background-image: url("@/assets/images/fat-cover-photo-bg-career.jpg");
  .header-bg {
    background: url("@/assets/images/coverphoto-career.png") no-repeat 100% 0;
    position: absolute;
    width: 1340px;
    height: 960px;
    top: 120px;
    left: 60%;
    margin-left: -526px;
    display: block;
    z-index: 1;
    background-size: auto 960px;
  }
  h1 {
    position: absolute;
    top: 312px;
    left: 0;
  }
  .base-cont {
    position: absolute;
    top: 452px;
    left: 0;
    width: 524px;
    height: 192px;
    font-family: "Poppins-Light";
    font-size: 18px;
    font-weight: 300;
    line-height: 1.78;
    letter-spacing: 0.5px;
    color: var(--light-sky-blue);
  }
}
.inner-cont {
  position: relative;
}
.style-dark {
  .inner-cont {
    height: 58vh;
    overflow: hidden;
  }
}
.page-content {
  position: relative;
  .contact-us {
    position: absolute;
    top: 98px;
    left: 720px;
    width: 702px;
    height: 288px;
    border-radius: 32px;
    background-color: var(--bg-content-normal);
    > div {
      padding-left: 179px;
      padding-right: 90px;
      padding-top: 65.5px;
    }
    > img {
      position: absolute;
      height: 331px;
      left: -90px;
      top: -21.5px;
    }
    dl {
      display: flex;
      align-items: center;
      position: relative;
      height: 36px;
      padding-top: 23px;
      padding-bottom: 7.5px;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        width: 433px;
        height: 0.5px;
        opacity: 0.4;
        background-color: var(--sapphire);
      }
    }
    dd {
      font-family: "Poppins-Light";
      font-size: 18px;
      font-weight: 300;
      line-height: 2;
      letter-spacing: 0.38px;
      color: var(--sapphire);
      margin-left: 8px;
    }
  }
  .btn-box {
    position: absolute;
    bottom: 60px;
    right: 0;
    text-align: right;
    box-sizing: border-box;
    line-height: 0;
    font-size: 0;
    margin: 0;
    .btn-primary {
      text-align: right;
      margin: 0;
    }
  }
}
.btn-cont {
  padding-top: 40px;
  text-align: right;
}
.career-list-warp {
  padding: 0 50px 0 360px;
}

.career-list {
  display: flex;
  flex-wrap: wrap;
  width: 973px;
  li {
    position: relative;
    width: 254px;
    height: 439px;
    margin-top: 160px;
    color: var(--sapphire);
    background-color: var(--bg-content-normal);
    cursor: pointer;
    margin-right: 104px;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      @height: 160px;
      bottom: -@height;
      height: @height;
      width: 0.5px;
      opacity: 0.4;
      background-color: var(--navy-blue);
    }
    &:nth-last-of-type(3n),
    &:nth-last-of-type(3n-1),
    &:nth-last-of-type(3n-2) {
      &::after {
        @height: 240px;
        bottom: -@height;
        height: @height;
      }
    }
    &:nth-last-of-type(3n-3),
    &:nth-last-of-type(3n-4) {
      &::after {
        @height: 839px;
        bottom: -@height;
        height: @height;
      }
    }

    .title {
      // text-transform: uppercase; // 大写
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.5px;
      padding: 36px 16px 16px;
    }
    .subtitle {
      // text-transform: uppercase; // 大写
      padding-top: 4px;
      font-size: 36px;
      padding: 0 16px 51px;
      font-family: "Poppins-Light";
      font-size: 18px;
      font-weight: 300;
      line-height: 1.33;
      letter-spacing: 0.5px;
    }
    .date {
      padding: 0 16px 51px;
      opacity: 0.6;
      font-family: "Poppins-Light";
      font-size: 12px;
      font-weight: 300;
      line-height: 1.5;
      letter-spacing: 0.38px;
      opacity: 0.6;
    }
    .link {
      font-family: "Poppins-Light";
      font-size: 18px;
      font-weight: 300;
      line-height: 36px;
      height: 36px;
      line-height: 2;
      letter-spacing: 0.38px;
      position: absolute;
      right: 16px;
      bottom: 36px;
      i,
      b {
        vertical-align: middle;
        line-height: 36px;
        display: inline-block;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .page-content {
    .contact-us {
      width: 582px;
      left: 670px;
      > div {
        padding-left: 109px;
        padding-right: 40px;
      }
    }
    .career-list li {
      margin-right: 65px;
    }
  }
}
@media screen and (max-width: 1060px) {
  .page-content {
    .contact-us {
      width: 582px;
      position: relative;
      left: 100px;
      > div {
        padding-left: 109px;
        padding-right: 40px;
      }
    }
    .career-list li {
      margin-right: 65px;
    }
  }
}
</style>
