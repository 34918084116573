<template>
  <div class="menu">
    <ul>
      <li v-for="item in menuList" :key="item.name" @click="handleEmit(item)">
        <router-link :to="{ path: item.url }" v-if="item.isLink">
          <span class="inner">
            <span class="link">{{ item.name }}</span>
            <span class="hover">{{ item.name }}</span>
          </span>
        </router-link>
        <a
          @click="openLink(item.url)"
          v-else-if="!item.isLink && item.url.indexOf('void') === -1"
        >
          <!-- 非空链接 -->
          <span class="inner">
            <span class="link">{{ item.name }}</span>
            <span class="hover">{{ item.name }}</span>
          </span>
          <!-- 空链接 -->
        </a>
        <span class="disabled-text" v-else>
          {{ item.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuList",
  data: () => {
    return {
      menuList: [
        // TODO:应审后开放隐藏
        // {
        //   name: "Cooperate",
        //   url: "/cooperate",
        //   isLink: true,
        // },
        {
          name: "BUY CRYPTO",
          url: "/buycrypto",
          isLink: false,
        },
        {
          name: "DOCs",
          url: "javascript:void(0)",
          isLink: false,
        },
        {
          name: "Community",
          url: "#community",
          isLink: false,
        },
        {
          name: "Career",
          url: "/career",
          isLink: true,
        },
        {
          name: "Contact",
          url: "/contact",
          isLink: true,
        },
      ],
    };
  },
  methods: {
    handleEmit(item) {
      if (item.url !== "javascript:void(0)") {
        this.$emit("changeRoute", item);
      }
    },
    openLink(url) {
      location.assign(url);
    },
  },
};
</script>
