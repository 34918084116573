<template>
  <div class="topbar-mobile" :class="isShowMenu ? 'activity' : ''">
    <div class="top-bar" :class="isDarkBar">
      <div class="inner-cont inner">
        <div class="logo" @click="handleChangeRoute">
          <router-link :to="{ path: '/' }">
            <img
              src="@/assets/images/logo-combination.png"
              class="logo-light"
              alt=""
            />
            <img src="@/assets/images/logo-icon.svg" class="logo-dark" alt="" />
          </router-link>
        </div>
        <div class="show-menu" @click="handleShowMenu">
          <i class="el-icon-more" v-if="!isShowMenu" />
          <i class="icon-close" v-if="isShowMenu" />
        </div>
      </div>
    </div>
    <div class="layer-menu" v-show="isShowMenu" @click.stop.prevent>
      <menu-list @changeRoute="handleChangeRoute" />
    </div>
  </div>
</template>

<script>
import MenuList from "./Menu";
// import "./TopTabMobileBar.less"; // TODO: css 全局污染的问题
export default {
  name: "TopTabMobileBar",
  data() {
    return {
      isShowMenu: false,
    };
  },
  components: {
    MenuList,
  },
  props: {
    barStyle: {
      type: String,
      default: "normal",
      validator: function (value) {
        // The value must match one of these strings
        return ["normal", "dark"].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    isDarkBar() {
      const classNames = {
        normal: "",
        dark: "dark-bar",
      };
      const styleType = this.barStyle;
      return classNames[styleType] || "";
    },
  },
  methods: {
    handleShowMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
    handleChangeRoute(item) {
      if (item.url === "#community") {
        // 跳转页位
        document.getElementById("PageFoot").scrollIntoView();
      }
      this.isShowMenu = false;
    },
  },
};
</script>
<style scoped src="./TopTabMobileBar.less" lang="less"></style>
