<template>
  <div class="layer-dialog" ref="dialog" v-if="isShow">
    <div class="dialog-header">
      <div class="dialog-close" @click="onClose">
        <i class="el-icon-close" />
      </div>
    </div>
    <div class="dialog-body">
      <div class="body-inner">
        <slot></slot>
      </div>
    </div>
    <div class="layer-mask" @click.stop.prevent="onClose"></div>
  </div>
</template>

<script>
export default {
  name: "FatDialog",
  mounted() {
    document.body.style.overflow = this.isShow ? "hidden" : "";
  },
  watch: {
    isShow(val) {
      document.body.style.overflow = val ? "hidden" : "";
    },
  },
  props: {
    onClose: {
      type: Function,
      required: false,
      default: () => {},
    },
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@contWidth: 1060px;
.layer-dialog {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  z-index: 99;
}
.dialog-header {
  height: 50px;
  top: 50px;
  position: fixed;
  width: @contWidth;
  left: 50%;
  z-index: 9;
  margin-left: -(@contWidth / 2);
  .dialog-close {
    position: absolute;
    display: inline-block;
    right: -40px;
    bottom: 0;
    text-align: center;
    line-height: 40px;
    width: 40px;
    height: 40px;
    color: #cae1e7;
    font-size: 30px;
    &:hover {
      cursor: pointer;
      color: #71dffb;
    }
  }
}
.dialog-body {
  background: #fff;
  position: relative;
  z-index: 8;
  border-radius: 32px;
  width: @contWidth;
  margin: 98px auto;
  box-sizing: border-box;
  padding: 160px 178px;
}
.layer-mask {
  background-color: var(--navy-blue);
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}
// 小于固定宽度时，关闭按钮移入弹层内
@media screen and (max-width: @contWidth) {
  .dialog-header {
    width: auto;
    margin-left: 0;
    left: auto;
    right: 50px;
    top: 20px;
    margin: 0 20px;
    padding: 10px;
    .dialog-close {
      z-index: 10;
      // color: var(--navy-blue);
      background-color: var(--navy-blue-op6);
      border-radius: 100%;
    }
  }
  .dialog-body {
    width: auto;
    margin-left: 0;
    margin: 100px 20px;
  }
}
</style>
