<template>
  <div v-scroll="handleScroll">
    <!-- screen1 -->
    <div class="focus-header home-header">
      <div class="inner-cont">
        <div class="header-cont1">
          <div class="main">
            <h1>A Crypto Economic Infrastructure Impeller</h1>
            <p class="info">Make crypto world accessible to every one</p>
          </div>
          <div class="click" id="click-cont">
            <img src="@/assets/images/icon-line-mouse.svg" class="icon" />
            <p>Designed for crypto inclusion</p>
          </div>
        </div>
        <div class="header-bg" />
        <div class="header-cont2">
          <div class="page-content">
            <div class="inner-cont">
              <div class="page-title">
                <strong>OVERVIEW</strong>
                <h2>What is FaT <i class="font-Roboto">?</i></h2>
              </div>
              <div class="cont-level1">
                <p>
                  FaT stands for “Fast and Trust”, a simple but
                  huge-effort-needing goal of the whole crypto world.
                </p>
                <p>
                  FaT is dedicated to continuously build and improve the
                  infrastructure of crypto world. By FaT Non-Exchange (NEX) and
                  FaT Pay-As-You-Wish (PAYW), FaT fuces the traditional world
                  and the crypto world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content style-light">
      <div class="inner-cont">
        <div class="page-title">
          <strong>OVERVIEW</strong>
          <h2>FaT Ecosystem</h2>
        </div>
        <div class="cont-image">
          <img
            v-if="showMobileImg"
            src="@/assets/images/invalid-name-mobile.png"
            alt=""
          />
          <img v-else src="@/assets/images/ecosystem-map.png" alt="" />
        </div>
      </div>
    </div>
    <div class="page-content style-dark">
      <div class="inner-cont overflow-hidden">
        <div class="page-title">
          <strong>FaT INFRASTRUCTURE</strong>
          <h2>Non-Exchange (NEX)</h2>
        </div>
        <div class="cont-level1">
          <p>
            Unlike CEX, NEX is a healthier scheme in which all project (coins,
            DAOs, NFTs etc.) initiators can reach out for users freely, promote
            freely and trade freely. That’s what FaT-NEX helps.
          </p>
        </div>
        <!-- TODO:纵向滚动 -->
        <div class="cont-level2">
          <div class="show-list1" ref="showList1" id="showList1">
            <div class="progress">
              <div class="progress-block" id="progressBlock"></div>
            </div>
            <ul>
              <li id="showList1-cont1">
                <div class="img">
                  <img
                    src="@/assets/images/icon-3-d-nex-trading-kit.png"
                    alt=""
                  />
                </div>
                <div class="cont">
                  <div class="title">Trading Freely</div>
                  <div class="subtitle">FaT-NEX Trading KIT</div>
                  <div class="text">
                    It can be an addon, an extension or a plug-in module which
                    attaches on all the channels and platforms that used for
                    user engaging. Users can buy tokens, NFTs or other services
                    directly with fiat currencies or crypto assets as they will.
                  </div>
                </div>
              </li>
              <li id="showList1-cont2">
                <div class="img">
                  <img
                    src="@/assets/images/icon-3-d-nex-marketing-system.png"
                    alt=""
                  />
                </div>
                <div class="cont">
                  <div class="title">Marketing Freely</div>
                  <div class="subtitle">FaT-NEX Marketing Tool</div>
                  <div class="text">
                    A system provides series of tools to one-click set up social
                    pages of project in mainstream platforms like twitter,
                    facebook, mirror.xzy, medium etc.
                  </div>
                </div>
              </li>
              <li id="showList1-cont3">
                <div class="img">
                  <img src="@/assets/images/icon-3-d-nex-scrm.png" alt="" />
                </div>
                <div class="cont">
                  <div class="title">Promoting Freely</div>
                  <div class="subtitle">FaT-NEX SCRM</div>
                  <div class="text">
                    A FaT-NEX Social Customer Relationship Management system
                    helps manage community members in Telegram, Twitter,
                    Facebook and Discord. It provides tools that facilitate all
                    sorts of activites in the community.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- screen2 -->
    <div class="page-content style-dark">
      <div class="inner-cont">
        <div class="page-title">
          <strong>FaT INFRASTRUCTURE</strong>
          <h2>Pay-As-You-Wish (PAYW)</h2>
        </div>
        <div class="cont-level1">
          <p>
            FaT-PAYW is a payment solution by which payer can pay with whatever
            currency they want, including fiat and crypto currencies.
          </p>
        </div>
        <lazy-component>
          <div class="cont-level2">
            <ul class="show-list2">
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-pay.svg" alt="" />
                </div>
                <div class="cont">
                  <div class="title">Auto-exchange</div>
                  <div class="text">
                    Payer can pay with any token in any chain, any standard.
                    Payee can collect any token he wants, no matter what the
                    payer pay. The conversion is done automatically and
                    instantly.
                  </div>
                </div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-fast.svg" alt="" />
                </div>
                <div class="cont">
                  <div class="title">Lightning-Fast</div>
                  <div class="text">
                    The transaction can be confirmed in milliseconds.
                  </div>
                </div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-fee.svg" alt="" />
                </div>
                <div class="cont">
                  <div class="title">Low Fee</div>
                  <div class="text">Affordable to everyone.</div>
                </div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-code.svg" alt="" />
                </div>
                <div class="cont">
                  <div class="title">Developer-friendly</div>
                  <div class="text">
                    Comprehensive integration documents. 24/7 technical support.
                    Active developer community.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </lazy-component>
      </div>
    </div>
    <!-- screen3 -->
    <div class="page-content style-light overflow-hidden">
      <div class="inner-cont">
        <div class="page-title">
          <strong>SYSTEM</strong>
          <h2>FaT Supports</h2>
        </div>
        <lazy-component>
          <!-- TODO:竖线 -->
          <div class="cont-level2">
            <ul class="show-list3">
              <li>
                <div class="title">150+</div>
                <div class="subtitle">countries</div>
                <div class="img">
                  <img src="@/assets/images/supports-countries.png" alt="" />
                </div>
              </li>
              <li>
                <div class="title">40+</div>
                <div class="subtitle">fiats</div>
                <div class="img">
                  <img src="@/assets/images/supports-fiat.png" alt="" />
                </div>
              </li>
              <li>
                <div class="title">100+</div>
                <div class="subtitle">tokens</div>
                <div class="img">
                  <img src="@/assets/images/supports-token.png" alt="" />
                </div>
              </li>
            </ul>
          </div>
        </lazy-component>
      </div>
    </div>
    <div class="page-content style-dark page-relative overflow-hidden">
      <div class="inner-cont">
        <div class="page-title">
          <strong>SYSTEM</strong>
          <h2>Transactions on Your Own DApp / Web / App</h2>
        </div>
        <div class="cont-level1">
          <p>
            By one simple integration with FaT-NEX, let all transactions go
            within your own site.
          </p>
        </div>
        <lazy-component>
          <div class="cont-level2">
            <ul class="show-list4">
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-integration.svg" alt="" />
                </div>
                <div class="title">One-key Integration</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-revenue.svg" alt="" />
                </div>
                <div class="title">Boost Revenue</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-security.svg" alt="" />
                </div>
                <div class="title">Secure</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-global.svg" alt="" />
                </div>
                <div class="title">Global Coverage</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-anti-fraud.svg" alt="" />
                </div>
                <div class="title">Anti-Fraud</div>
              </li>
            </ul>
          </div>
        </lazy-component>
      </div>
      <lazy-component v-if="showMobileImg">
        <div class="mobile-img">
          <img class="img1" src="@/assets/images/pc-mobile.png" alt="" />
          <img class="img2" src="@/assets/images/pc-1-mobile.png" alt="" />
        </div>
      </lazy-component>
      <lazy-component v-else>
        <div class="page-bg1">
          <div class="img1"></div>
          <div class="img2"></div>
        </div>
      </lazy-component>
    </div>
    <div class="page-content style-dark overflow-hidden">
      <div class="inner-cont page-relative">
        <div class="page-title">
          <strong>SYSTEM</strong>
          <h2>Collect Any Crypto As You Wish</h2>
        </div>
        <lazy-component>
          <div class="cont-level2">
            <ul class="show-list4">
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-integration.svg" alt="" />
                </div>
                <div class="title">One-key Integration</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-fast.svg" alt="" />
                </div>
                <div class="title">Lightning-Fast<br />Payment</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-friendly.svg" alt="" />
                </div>
                <div class="title">Customer-Friendly</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-support.svg" alt="" />
                </div>
                <div class="title">Muti-Platform<br />Support</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-token.svg" alt="" />
                </div>
                <div class="title">100+ token</div>
              </li>
              <li>
                <div class="img">
                  <img src="@/assets/images/icon-line-fiat.svg" alt="" />
                </div>
                <div class="title">40+ fiat</div>
              </li>
            </ul>
          </div>
        </lazy-component>
        <!--  -->
        <lazy-component @show="loadingBg">
          <div class="page-bg2">
            <div class="img1">
              <img src="@/assets/images/phone1.png" alt="" />
            </div>
            <div class="img2">
              <img src="@/assets/images/phone2.png" alt="" />
            </div>
            <div class="img3">
              <img src="@/assets/images/phone3.png" alt="" />
            </div>
            <div class="img4">
              <img src="@/assets/images/phone4.png" alt="" />
            </div>
          </div>
        </lazy-component>
      </div>
    </div>
    <!-- screen10 -->
    <div class="page-content style-light page-relative">
      <div class="inner-cont page-relative">
        <div class="page-title">
          <strong>OUR JOURNEY</strong>
          <h2>Where we’re heading to</h2>
        </div>
        <div class="show-list5-control">
          <div
            :class="`arrow-prev${showList5Left === 0 ? ' arrow-disable' : ''}`"
            @click="showList5('prev')"
          >
            <i class="icon-arrow" />
          </div>
          <div
            :class="`arrow-next${
              showList5BlockByPercent === 100 ? ' arrow-disable' : ''
            }`"
            @click="showList5('next')"
          >
            <i class="icon-arrow" />
          </div>
        </div>
      </div>
      <div class="show-list5">
        <!-- TODO: 横向滚动条  -->
        <div class="list-scroll-content" ref="showList5Scroll">
          <div
            class="list-scroll-block"
            :style="{ left: `${showList5BlockByPercent}%` }"
          />
        </div>
        <dl
          class="list"
          ref="showList5"
          :style="{ marginLeft: showList5Left + 'px' }"
        >
          <dt class="typename">2022</dt>
          <dd class="typecont">
            <div class="title">01.</div>
            <div class="text">
              Public release FaT-NEX for crypto communities and starups
            </div>
          </dd>
          <dd class="typecont">
            <div class="title">02.</div>
            <div class="text">Partner development of FaT-NEX</div>
          </dd>
          <dd class="typecont">
            <div class="title">03.</div>
            <div class="text">
              Activate FaT-NEX Swap, a multi-public-chain based NEX smart
              contract
            </div>
          </dd>
          <dd class="typecont">
            <div class="title">04.</div>
            <div class="text">Public release FaT-PAYW for global merchant</div>
          </dd>
          <dd class="typecont">
            <div class="title">05.</div>
            <div class="text">Merchant development of FaT-PAYW</div>
          </dd>
          <dt class="typename">2023</dt>
          <dd class="typecont">
            <div class="title">01.</div>
            <div class="text">
              Initiation of FaTDAO, a DAO of the community, by the community,
              for the community
            </div>
          </dd>
          <dd class="typecont">
            <div class="title">02.</div>
            <div class="text">
              FaT Chain- an EVM compatible public chain with 0-gas realtime
              transaction support
            </div>
          </dd>
          <dd class="typecont">
            <div class="title">03.</div>
            <div class="text">
              DeFi ecosystem building under the governance of FaT community
            </div>
          </dd>
        </dl>
      </div>
    </div>
    <!-- screen12 -->
    <div class="page-content style-dark">
      <div class="inner-cont">
        <div class="page-title">
          <strong>Growing Ecosystem</strong>
          <h2>Our Partners</h2>
        </div>
      </div>
      <lazy-component>
        <div class="overflow-hidden over-lists">
          <marquee-text :duration="20" :repeat="3">
            <div class="show-list6">
              <ul class="type-logo">
                <li><img src="@/assets/images/logo-eth.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-pol.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-cel.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-bin.svg" alt="" /></li>
              </ul>
            </div>
          </marquee-text>
          <marquee-text :duration="25" :repeat="3">
            <div class="show-list6">
              <ul class="type-logo">
                <li><img src="@/assets/images/logo-ter.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-cha.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-arb.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-cos.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-opt.svg" alt="" /></li>
              </ul>
            </div>
          </marquee-text>
          <!-- slow duration -->
          <marquee-text :duration="30" :repeat="3">
            <div class="show-list6">
              <ul class="type-card">
                <li><img src="@/assets/images/logo-card-visa.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-card-usa.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-card-mast.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-card-jcb.svg" alt="" /></li>
                <li><img src="@/assets/images/logo-card-scpa.svg" alt="" /></li>
              </ul>
            </div>
          </marquee-text>
        </div>
      </lazy-component>
    </div>
    <!-- screen13 -->
    <div class="page-content style-light overflow-hidden spc-lists">
      <div class="inner-cont">
        <div class="page-title">
          <strong>MEET THE PEOPLE</strong>
          <h2>Who we are <i class="font-Roboto">?</i></h2>
        </div>
        <div class="cont-level1 mobile-level">
          <p>The core members are from Alipay, TikTok and Weibo.</p>
        </div>
        <lazy-component>
          <div class="cont-level2">
            <ul class="show-list8 overflow-hidden">
              <li><img src="@/assets/images/logo-tik-tok.svg" alt="" /></li>
              <li><img src="@/assets/images/logo-alipay.svg" alt="" /></li>
              <li><img src="@/assets/images/logo-weibo.svg" alt="" /></li>
              <li><img src="@/assets/images/logo-alibaba.svg" alt="" /></li>
            </ul>
          </div>
        </lazy-component>
      </div>
    </div>
    <!-- screen14 -->
    <page-footer></page-footer>
  </div>
</template>

<script>
import Vue from "vue";
import "./../styles/HomeView.less";
import PageFooter from "../components/PageFooter.vue";
import MarqueeText from "vue-marquee-text-component";
import VueLazyload from "vue-lazyload";
import { deviceInfo } from "@/kits/tools";

Vue.use(VueLazyload, {
  preLoad: 2,
  attempt: 1,
  lazyComponent: true,
});

const showList5ActivityStep = 6;
let showList5StepIndex = 0;
let widthBetweenLeftScreenAndInner = "";
const marginGrid = 180; // grid is 180
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    MarqueeText,
    PageFooter,
  },
  data: () => {
    return {
      showCont1: true,
      showList5BlockByPercent: 0,
      showList5Left: 0,
      showMobileImg: false,
    };
  },
  created() {
    // 移动端适配
    if (deviceInfo.isMobile) {
      this.showMobileImg = true;
    }
  },
  destroyed() {
    window.removeEventListener("scroll", () => {});
  },
  mounted() {
    this.showList1Activity();
    this.showList5Activity();
  },
  methods: {
    handleScroll() {
      const windowY = window.scrollY; // 滚动高度
      const nodeClickCont = document.getElementById("click-cont");
      if (nodeClickCont) {
        if (windowY > 50) {
          nodeClickCont.style.opacity = 0;
        } else if (windowY === 0) {
          nodeClickCont.style.opacity = 1;
        }
      }
      // Non-Exchange (NEX)
      this.showList1Activity();
    },
    loadingBg(component) {
      console.log("loadingBg", component);
    },
    // 滚动条交互
    showList1Activity() {
      const windowY = window.scrollY;
      const listNode = document.getElementById("showList1");
      const listNodeClassName = listNode.className;
      if (!listNodeClassName) {
        return;
      }
      const listClassName = "show-list1";
      const listTop = listNode.offsetTop;
      const nodeProgressBlock = document.querySelector(
        "#showList1 .progress .progress-block"
      );
      // 屏幕可视区域中心
      const clientMiddleOffset = Math.floor(window.innerHeight / 2);
      // const blockStopScroolY = 0;
      const listCont1Top =
        listTop + document.getElementById("showList1-cont1").offsetTop;
      const listCont2Top =
        listTop + document.getElementById("showList1-cont2").offsetTop;
      const listCont3Top =
        listTop + document.getElementById("showList1-cont3").offsetTop;

      if (listNode) {
        document.querySelector("#showList1 .progress").style.height =
          listNode.offsetHeight + "px";
        if (
          listNodeClassName.indexOf("step1") === -1 &&
          windowY > listCont1Top - clientMiddleOffset &&
          windowY < listCont2Top - clientMiddleOffset
        ) {
          listNode.className = `${listClassName} step1`;
          nodeProgressBlock.style.position = "fixed";
          nodeProgressBlock.style.top = "50%";
        } else if (
          listNodeClassName.indexOf("step2") === -1 &&
          windowY > listCont2Top - clientMiddleOffset &&
          windowY < listCont3Top - clientMiddleOffset
        ) {
          listNode.className = `${listClassName} step2`;
          nodeProgressBlock.style.position = "fixed";
          nodeProgressBlock.style.top = "50%";
        } else if (
          listNodeClassName.indexOf("step3") === -1 &&
          windowY > listCont3Top - clientMiddleOffset
        ) {
          listNode.className = `${listClassName} step3`;
          // TODO:最后一屏在大显示器及手机端下有兼容性问题
          nodeProgressBlock.style.position = "absolute";
          let cont3Top = document.getElementById("showList1-cont3").offsetTop;
          if (deviceInfo.isMobile) {
            cont3Top = cont3Top - 80; // TODO: 移动端修正值
          }
          nodeProgressBlock.style.top = cont3Top + "px";
        } else if (
          listNodeClassName.indexOf("step1") > -1 &&
          windowY < listCont1Top - clientMiddleOffset
        ) {
          listNode.className = listClassName;
          nodeProgressBlock.style.position = "static";
          nodeProgressBlock.style.top = "";
        }
      }
    },
    // 横向列表初始化
    showList5Activity() {
      const innerWidth =
        document.getElementsByClassName("inner-cont")[0].offsetWidth;
      widthBetweenLeftScreenAndInner = (window.innerWidth - innerWidth) / 2;
      const newMargin = `${widthBetweenLeftScreenAndInner + marginGrid}px`;

      let domScrollLeft = newMargin;
      let domScrollRight =
        widthBetweenLeftScreenAndInner +
        document.querySelector(".list-scroll-content .list-scroll-block")
          .offsetWidth +
        "px";
      // 移动端兼容
      if (deviceInfo.isMobile) {
        domScrollLeft = "0.48rem";
        domScrollRight = "0.48rem";
      }
      // UI适配
      this.$refs["showList5"].style.left = domScrollLeft;
      this.$refs["showList5Scroll"].style.left = domScrollLeft;
      this.$refs["showList5Scroll"].style.right = domScrollRight;
    },
    showList5(option) {
      const listRef = this.$refs["showList5"];
      const dtNum = listRef.querySelectorAll("dt").length;
      const ddNum = listRef.querySelectorAll("dd").length;
      let listWidth = dtNum * 84 + ddNum * 354 - 100; // 乘数来自css渲染 dt 84px,dd 354px,减去最后一个右边距 100

      const step = Math.floor(
        (listWidth -
          (innerWidth - widthBetweenLeftScreenAndInner - marginGrid * 2)) /
          showList5ActivityStep
      ); // 位移算法
      let showList5Left = this.showList5Left;
      if (option === "prev") {
        if (showList5Left === 0) {
          return false;
        }
        showList5Left += step;
        console.log("prev");
        showList5StepIndex--;
      } else {
        if (this.showList5BlockByPercent === 100) {
          return false;
        }
        console.log("next");
        showList5Left -= step;
        showList5StepIndex++;
      }
      if (showList5Left === 0) {
        // TODO: disable
      }
      // console.log(
      //   "showList5",
      //   showList5StepIndex,
      //   widthBetweenLeftScreenAndInner,
      //   step,
      //   innerWidth,
      //   listWidth
      // );

      // 移动端兼容
      // if (deviceInfo.isMobile) {
      //   listWidth = px2rem(listWidth);
      // }
      this.showList5Left = showList5Left;
      this.showList5BlockByPercent =
        showList5StepIndex === showList5ActivityStep
          ? 100
          : Math.floor((100 * showList5StepIndex) / showList5ActivityStep);
    },
  },
};
</script>
