const deviceInfo = (function () {
  const htmlUnitFontSize = window
    .getComputedStyle(document.getElementsByTagName("html")[0], null)
    .getPropertyValue("font-size");
  let sUserAgent = navigator.userAgent.toLowerCase();
  let bIsIpad = /ipad/gi.test(sUserAgent);
  let bIsIphoneOs = /iphone/gi.test(sUserAgent);
  let bIsAndroid = sUserAgent.indexOf("android") > -1;
  let deviceStyle = document.getElementsByTagName("body")[0];
  const bIsMobile = bIsIphoneOs || bIsAndroid;
  if (bIsMobile) {
    deviceStyle.className = "fat-mobile";
  } else if (bIsIpad) {
    deviceStyle.className = "fat-tablet";
  } else {
    deviceStyle.className = "fat-pc";
  }
  const deviceInfoData = {
    htmlUnitFontSize: parseFloat(htmlUnitFontSize),
    isIPhone: bIsIphoneOs,
    isIPad: bIsIpad,
    isAndroid: bIsAndroid,
    screen: window.screen,
    styleName: deviceStyle,
    isMobile: bIsMobile,
  };
  return deviceInfoData;
})();

const deviceFontSizeFormat = {
  timer: null,
  opt: {},
  init: function (t) {
    (this.opt.frame = t.frame || 750),
      (this.opt.ratio = t.ratio || 100),
      (this.opt.maxWidth = t.maxWidth || 480),
      this.bindEvents(),
      this.setSize(),
      this.osInfo();
  },
  setSize: function () {
    var t = document.documentElement,
      e = parseInt(t.clientWidth),
      i = parseInt(t.clientHeight);
    let baseFont = 0;
    (baseFont = (e / this.opt.frame) * this.opt.ratio),
      (baseFont =
        ((e > this.opt.maxWidth ? this.opt.maxWidth : e) / this.opt.frame) *
        this.opt.ratio),
      (t.style.fontSize = baseFont + "px"),
      i / e <= 1.625
        ? t.classList.add("low")
        : t.classList.contains("low") && t.classList.remove("low");
  },
  bindEvents: function () {
    var t = this;
    window.addEventListener("resize", function () {
      t.timer && clearTimeout(t.timer),
        (t.timer = setTimeout(
          function () {
            t.setSize();
          },
          300,
          !1
        ));
    });
  },
  osInfo: function () {
    var t = document.documentElement,
      e = navigator.userAgent,
      i = e.indexOf("Android") > -1 || e.indexOf("Adr") > -1,
      s = !!e.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      o = parseInt(window.devicePixelRatio),
      n = {};
    let a = "";
    if (s) {
      t.setAttribute("data-os", "iOS"), t.setAttribute("data-dpr", o);
      a = e.match(/OS (\d+)_(\d+)_?(\d+)?/);
      n = {
        os: "iOS",
        version:
          parseInt(a[1], 10) +
          "." +
          parseInt(a[2], 10) +
          "." +
          parseInt(a[3] || 0, 10),
      };
    } else if (i) {
      t.setAttribute("data-os", "Android"),
        (o = o >= 3 ? 3 : o >= 2 ? 2 : 1),
        t.setAttribute("data-dpr", o);
      a = e.match(/Android\s([0-9.]*)/);
      n = {
        os: "Android",
        version: a[1],
      };
    } else
      n = {
        os: "desktop",
        version: "desktop",
      };
    return n;
  },
};

/**
 * 千分位
 * @param {number} num
 * @returns {string}
 */
function formatThousand(num) {
  const numStr = String(num);
  const formater = (str) => str.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  if (numStr.indexOf(".") > -1) {
    const arr = numStr.split(".");
    return `${formater(arr[0])}.${arr[1]}`;
  } else {
    return formater(numStr);
  }
}

/**
 *
 * @param {number} pxNum [移动端像素]
 * @returns {string} [rem单位数字]
 */
function px2rem(pxNum, unit) {
  // px转rem方法
  // 因为设计稿尺寸是375的，tools.js里的基本尺寸给的是750的，所以需要除以50
  const remNum = (Number.parseFloat(pxNum) / 50).toFixed(2);
  return unit ? remNum + unit : remNum;
}

export { deviceInfo, deviceFontSizeFormat, formatThousand, px2rem };
