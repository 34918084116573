import { render, staticRenderFns } from "./JdDetail.vue?vue&type=template&id=56bc3722&scoped=true&"
import script from "./JdDetail.vue?vue&type=script&lang=js&"
export * from "./JdDetail.vue?vue&type=script&lang=js&"
import style0 from "./JdDetail.vue?vue&type=style&index=0&id=56bc3722&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_m252fu66fivcrdajfhoxhvxrne/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56bc3722",
  null
  
)

export default component.exports