<template>
  <div class="page-content style-dark page-foot" id="PageFoot">
    <a name="community" />
    <div class="inner-cont page-relative">
      <div class="page-title">
        <h2>Join the Community</h2>
        <p class="page-text1">
          Learn more about Fat Pay, chat with the talents, others in the
          community, and have your say in shaping the future of Digital Money.
        </p>
      </div>
      <div class="show-list9">
        <div class="row" @click="openUrl('mailto:hello@fatpay.xyz')">
          <div class="icon">
            <img src="@/assets/images/icon-face-link-email.svg" alt="" />
          </div>
          <div class="title">Email</div>
          <div class="text">hello@fatpay.xyz</div>
        </div>
        <!-- <div class="row">
          <div class="icon">
            <img src="@/assets/images/icon-face-link-facebook.svg" alt="" />
          </div>
          <div class="title">Facebook</div>
          <div class="text">@fatpay.xyz</div>
        </div> -->
        <div class="row" @click="openUrl('https://twitter.com/realFatPay')">
          <div class="icon">
            <img src="@/assets/images/icon-face-link-twitter.svg" alt="" />
          </div>
          <div class="title">Twitter</div>
          <div class="text">@realFatPay</div>
        </div>
        <div class="row" @click="openUrl('https://medium.com/@fatpay')">
          <div class="icon">
            <img src="@/assets/images/icon-face-link-medium.svg" alt="" />
          </div>
          <div class="title">Medium</div>
          <div class="text">@FaTPay</div>
        </div>
        <!-- <div class="row">
          <div class="icon">
            <img src="@/assets/images/icon-face-link-telegram.svg" alt="" />
          </div>
          <div class="title">Telegram</div>
          <div class="text">@fatpay.xyz</div>
        </div>
        <div class="row">
          <div class="icon">
            <img src="@/assets/images/icon-face-link-github.svg" alt="" />
          </div>
          <div class="title">Github</div>
          <div class="text">@fatpay.xyz</div>
        </div>
        <div class="row">
          <div class="icon">
            <img src="@/assets/images/icon-face-link-linkedin.svg" alt="" />
          </div>
          <div class="title">Linked</div>
          <div class="text">@fatpay.xyz</div>
        </div> -->
      </div>
      <div class="page-bg4"></div>
      <div class="page-text2">
        <span>Copyright © FaTPay 2022</span>
        <a
          class="page-text2__terms"
          href="https://ramp.fatpay.xyz/staticpages/terms-of-use-privacy-policy.html"
          target="_blank"
        >
          Terms of Use & Privacy Policy
        </a>
      </div>
      <div class="page-text3">
        <p>
          All the services and solutions are provided, where applicable, by Fast
          and Trust Technology Limited UAB (registered in Lithuania,
          incorporation number 306029983), with address at Laisvés pr. 60,
          Vilnius, FAT TECHNOLOGY INC.(registered in Canada under incorporation
          number 1000282818, MSB registration number M22596457), with address at
          800 Steeles W Ave,Unit B10182,Thornhill,ON,Canada L4J7L2. FaTPay
          provides its services only via fatpay.xyz / fatpay.org / fatpay.co /
          fatpay.tech and the official APPs. FaTPay is not affiliated with any
          other similar websites and is not responsible for any acts takenby
          their owners.
        </p>
        <p>
          FaTPay provides its services only via fatpay.xyz. FaTPay is not
          affiliated with any other similar websites and is not responsible for
          any acts takenby their owners.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  methods: {
    openUrl(urlString) {
      window.open(urlString);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.page-foot {
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 76px;
  height: 100vh;
  min-height: 1080px;
  .inner-cont {
    min-height: 900px;
    font-family: "Poppins-Light";
  }
  .page-text1 {
    width: 690px;
    padding-top: 36px;
    line-height: 1.5;
  }
  .page-text2 {
    margin-top: 64px;
    font-size: 18px;
    line-height: 1.5;

    &__terms {
      font-size: 14px;
      margin-left: 20px;
    }
  }
  .page-text3 {
    margin-top: 16px;
    font-size: 12px;
    line-height: 1.5;
  }
}
.page-bg4 {
  width: 700px;
  height: 600px;
  background: url("@/assets/images/footer-image.png") no-repeat 100% 0;
  background-size: auto 100%;
  text-align: center;
  overflow: hidden;
  position: absolute;
  right: 100px;
  top: -40px;
}

.show-list9 {
  width: 590px;
  margin: 80px 0 0 90px;
  position: relative;
  z-index: 9;
  .row {
    position: relative;
    width: 590px;
    height: 48px;
    line-height: 48px;
    display: flex;
    padding-top: 16px;
    color: var(--light-sky-blue);
    &::before {
      content: "";
      display: block;
      width: 0;
      transition: width 0.5s ease-in-out 0.05s;
      height: 1px;
      position: absolute;
      z-index: 2;
      bottom: 0;
      background-color: var(--light-sky-blue);
      opacity: 1;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      z-index: 1;
      background-color: var(--light-sky-blue);
      opacity: 0.4;
    }
    .icon {
      width: 48px;
      height: 48px;
      img {
        width: 100%;
      }
    }
    .title {
      width: 200px;
      padding-top: 3px;
      transition: all 0.3s;
      font-family: "Poppins-Light";
    }
    .text {
      text-align: right;
      vertical-align: middle;
      flex-grow: 1;
      padding-top: 3px;
      font-family: "Poppins-Light";
    }
    &:hover {
      cursor: pointer;
      &::before {
        width: 100%;
      }
      .title {
        text-indent: 4px;
      }
    }
  }
}
</style>
