<template>
  <div id="app" v-scroll="handleScroll">
    <div class="header">
      <component :is="tab" :barStyle="barStyle" />
    </div>
    <div class="body">
      <router-view />
    </div>
  </div>
</template>

<script>
import TopTabBar from "@/components/TopBar/TopTabBar";
import TopTabMobileBar from "@/components/TopBar/TopTabMobileBar";
import { deviceInfo } from "@/kits/tools";

export default {
  name: "app",
  data() {
    return {
      /** @returns { string } */
      tab: "TopTabBar",
      barStyle: "normal",
      isScrollPage: true,
    };
  },
  components: {
    TopTabBar,
    TopTabMobileBar,
  },
  created() {
    if (deviceInfo.isMobile) {
      this.tab = "TopTabMobileBar";
    }
  },
  mounted() {
    if (location.pathname === "/buycrypto") {
      this.barStyle = "dark";
      this.isScrollPage = false;
    }
  },
  methods: {
    handleScroll() {
      if (!this.isScrollPage) {
        return;
      }
      const windowY = window.scrollY; // 滚动高度
      const changeBarHeight = deviceInfo.isMobile ? 50 : 240;
      // screen.availHeight > 1000 ? screen.availHeight : 1000;
      // 首屏
      if (windowY > changeBarHeight && this.barStyle !== "dark") {
        this.barStyle = "dark";
      } else if (windowY < changeBarHeight && this.barStyle === "dark") {
        this.barStyle = "normal";
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
