/* eslint-disable */
import Vue from "vue";
import {
    Button,
    Form,
    FormItem,
    Input,
    Select,
    Option,
    OptionGroup,
    Checkbox,
    CheckboxGroup,
    Radio,
    Row,
    Col,
    Switch,
    Icon,
} from "element-ui";

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(Row);
Vue.use(Col);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Icon);
