<template>
  <div id="imagemap" class="map">
    <swiper
      class="swiper-wrapper"
      :options="swiperOptions"
      ref="mapSwiper"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in markers"
        :class="'slide' + index"
        :key="index"
      >
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination" id="pagination" slot="pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import { Pagination } from "swiper";
import "swiper/css/swiper.css";

export default {
  name: "ImageMapMobile",
  data() {
    const data = {
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 0,
        loop: true,
        // modules: [Pagination],
        initialSlide: 4,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "progressbar",
        },
      },
      markers: [
        {
          label: "San Jose",
          top: "46%",
          left: "14%",
          title: "San Jose",
          address: "Coming Soon…",
        },
        {
          label: "Kaunas",
          top: "34.5%",
          left: "52.8%",
          title: "Kaunas",
          address: "3rd floor, Savanoriu pr. 221, Kaunas",
        },
        {
          label: "Cape Town",
          top: "81.5%",
          left: "53%",
          title: "Cape Town",
          address: "Coming Soon…",
        },
        {
          label: "Singapore",
          top: "63.5%",
          left: "77%",
          title: "Singapore",
          address: "9 Battery Road\nSingapore 049910",
        },
        // {
        //   label: "Hong Kong",
        //   top: "52.8%",
        //   left: "79%",
        //   title: "Hong Kong",
        //   address:
        //     "Unit 1001,8/F.,\nChinachem Golded Plaza,77 Mody Road, Tsim Sha Tsui, Kowloon, Hong Kong",
        // },
      ],
    };
    return data;
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSwiper(swiper) {
      console.log("onSwiper", swiper);
    },
    onSlideChange() {
      console.log("onSlideChange");
    },
  },
};
//
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less" src="./ImageMapMobile.less"></style>
