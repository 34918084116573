<template>
  <div id="imagemap" class="map">
    <div
      class="markpoint"
      v-for="(item, index) in markers"
      :key="index"
      :class="`markpoint-style-${index}`"
      :style="{ top: item.top, left: item.left }"
    >
      <i class="icon-point" />
      <span class="name">{{ item.label }}</span>
      <div class="layer-tooltip">
        <div class="cont">
          <div class="title">{{ item.title }}</div>
          <div class="subtitle">{{ item.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageMap",
  data() {
    return {
      markers: [
        {
          label: "San Jose",
          top: "46%",
          left: "14%",
          title: "San Jose",
          address: "Coming Soon…",
        },
        {
          label: "Kaunas",
          top: "34.5%",
          left: "52.8%",
          title: "Kaunas",
          address: "3rd floor, Savanoriu pr. 221, Kaunas",
        },
        {
          label: "Cape Town",
          top: "81.5%",
          left: "53%",
          title: "Cape Town",
          address: "Coming Soon…",
        },
        {
          label: "Hong Kong",
          top: "52.8%",
          left: "79%",
          title: "Hong Kong",
          address:
            "Unit 1001,8/F.,Chinachem Golded Plaza,77 Mody Road, Tsim Sha Tsui, Kowloon, Hong Kong",
        },
        {
          label: "Singapore",
          top: "63.5%",
          left: "77%",
          title: "Singapore",
          address: "9 Battery Road\nSingapore 049910",
        },
      ],
      tooltipIndexForShow: -1,
    };
  },
  methods: {
    handleHover(index) {
      console.log("handleHover", index);
      this.tooltipIndexForShow = index || -1;
    },
  },
};
//
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@height_width_rate: 0.6;
@tooptipLineHeight: 40px;
@tooptipLineWidth: 254px;
@contSideMargin: 16px;
@markpointPaddingWidth: 30px;
@markpointPaddingHeight: 20px;
.map {
  position: relative;
  background: url("@/assets/images/map.png") no-repeat;
  background-size: 100% 100%;
  height: 960px;
  .markpoint {
    position: absolute;
    padding: @markpointPaddingHeight @markpointPaddingWidth
      @markpointPaddingHeight (22px + @markpointPaddingWidth);
    height: 80px;
    line-height: 80px;
    margin: -60px 0 0 (-8px - @markpointPaddingWidth);
    font-family: "Poppins-Light";
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.5px;
    &.markpoint-style-3 {
      padding-bottom: 0;
      .layer-tooltip {
        bottom: 80px;
      }
    }
    &.markpoint-style-4 {
      padding-top: 0;

      top: 65.5% !important;
      .icon-point {
        top: 32px;
      }
    }
    .name {
      color: var(--navy-blue);
      transition: opacity 0.3s;
      cursor: default;
    }
    .icon-point {
      position: absolute;
      top: 32px + @markpointPaddingHeight;
      left: @markpointPaddingWidth;
      display: inline-block;
      background: url("@/assets/images/icon-line-location-checkeed.svg")
        no-repeat;
      width: 16px;
      height: 16px;
    }
    &:hover {
      .name {
        opacity: 0;
      }
      .icon-point {
        background: none;
        &::before {
          content: "";
          display: inline-block;
          position: relative;
          top: -32px;
          left: 6px;
          width: 4px;
          height: 4px;
          border-radius: 100%;
          background: #192a52;
          overflow: hidden;
        }
      }
      .layer-tooltip {
        z-index: 9;
        .cont {
          width: @tooptipLineWidth;
        }
        &::after {
          height: 40px;
        }
      }
    }
  }
  .layer-tooltip {
    z-index: -1;
    position: absolute;
    min-height: 100px;
    padding: 28px 0 45px;
    color: var(--light-sky-blue);
    background-color: var(--sapphire);
    bottom: 100px;
    left: 8px + @markpointPaddingWidth;
    box-sizing: border-box;
    .cont {
      width: 0;
      overflow: hidden;
      transition: width 0.2s ease-in-out 0.25s;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: -40px;
      height: 0;
      transition: all 0.3s;
      background-color: var(--navy-blue);
      opacity: 0.4;
      width: 0.5px;
      overflow: hidden;
    }
    .title {
      font-family: Poppins;
      font-size: 24px;
      width: @tooptipLineWidth - @contSideMargin * 2;
      margin: 0 @contSideMargin;
      box-sizing: border-box;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.5px;
    }
    .subtitle {
      font-family: "Poppins-Light";
      padding-top: 10px;
      margin: 0 @contSideMargin;
      width: @tooptipLineWidth - @contSideMargin * 2;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.33;
      letter-spacing: 0.5px;
      color: var(--light-sky-blue);
    }
  }
}

@media screen and (max-width: 1920px) {
  .map {
    width: 1420px;
    height: @height_width_rate * 1420px;
  }
}

@media screen and (max-width: 1440px) {
  .map {
    width: 1200px;
    height: @height_width_rate * 1200px;
  }
}
@media screen and (max-width: 1008px) {
  .map {
    width: 100%;
    height: @height_width_rate * 1008px;
  }
}
</style>
