<template>
  <div class="top-bar" :class="isDarkBar">
    <div class="inner-cont inner">
      <div class="logo">
        <router-link :to="{ path: '/' }">
          <img
            src="@/assets/images/logo-combination.png"
            class="logo-light"
            alt=""
          />
          <img src="@/assets/images/logo-icon.svg" class="logo-dark" alt="" />
        </router-link>
      </div>
      <menu-list />
    </div>
  </div>
</template>

<script>
import MenuList from "./Menu";
export default {
  name: "TopTabBar",
  components: {
    MenuList,
  },
  props: {
    barStyle: {
      type: String,
      default: "normal",
      validator: function (value) {
        // The value must match one of these strings
        return ["normal", "dark"].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    isDarkBar() {
      const classNames = {
        normal: "",
        dark: "dark-bar",
      };
      const styleType = this.barStyle;
      return classNames[styleType] || "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@tabbarHeight: 80px;
.top-bar {
  height: @tabbarHeight;
  width: 100%;
  padding-top: 20px;
  transition: all 0.3s;
  position: relative;
  .inner {
    display: flex;
    justify-content: space-between;
  }
  .logo-light {
    margin-top: 20px;
    width: 135px;
    height: 40px;
    display: block;
  }
  .logo-dark {
    margin-top: 20px;
    display: none;
  }
  &.dark-bar {
    padding-top: 0;
    background-color: var(--sapphire);
    .logo-light {
      display: none;
    }
    .logo-dark {
      display: block;
    }
    &::after {
      content: "";
      display: block;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      opacity: 0.4;
      background-color: var(--navy-blue);
    }
  }
  .logo {
    width: 300px;
  }
  /deep/ .menu {
    width: 500px;
    ul {
      display: flex;
      justify-content: flex-end;
    }
    li {
      height: @tabbarHeight;
      text-transform: uppercase;
      font-size: 14px;
      padding: 0 10px;
      color: #1244ac;
      .disabled-text {
        height: @tabbarHeight;
        padding-top: 34px;
        font-size: 14px;
        display: block;
      }
      a {
        height: @tabbarHeight;
        padding-top: 34px;
        font-size: 14px;
        position: relative;
        display: inline-block;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 54px;
          width: 100%;
          height: 1px;
          left: 0;
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
          background-color: var(--bright-cyan);
        }
        .inner {
          position: relative;
          display: block;
          overflow: hidden;
        }
        .link {
          font-family: "Poppins-Light";
          position: relative;
          display: block;
          transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        }
        .hover {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          transform: translateY(calc(100% + 41px));
          transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        }
        &:hover,
        &.router-link-exact-active {
          &::after {
            transform: scaleX(1);
            transform-origin: left;
          }
          .link {
            transform: translateY(-100%);
          }
          .hover {
            transform: translateY(0);
          }
        }
      }
    }
  }
}
</style>
