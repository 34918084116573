import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import CorporateView from "../views/CooperateView.vue";
import CareerView from "../views/CareerView.vue";
import ContactView from "../views/ContactView.vue";
import BuyCrypto from "../views/BuyCrypto.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cooperate",
    name: "Cooperate",
    component: CorporateView,
  },
  {
    path: "/career",
    name: "Career",
    component: CareerView,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  {
    path: "/buycrypto",
    name: "BuyCrypto",
    component: BuyCrypto,
  },
  {
    path: "/page404",
    name: "Page404",
    component: () =>
      import(/* webpackChunkName: 'Page404View' */ "../views/Page404View.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
