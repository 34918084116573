import Vue from "vue";
import App from "./App.vue";
import "./styles/index.less";
import router from "./router";
import "./plugins/element.js";
import { deviceInfo, deviceFontSizeFormat } from "./kits/tools";

Vue.config.productionTip = false;
// 滚动指令
Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

// 移动端样式
if (deviceInfo.isMobile) {
  import("./styles/mobile.less");
  console.log("loaded mobile.less");
  deviceFontSizeFormat.init({});
} else if (deviceInfo.isIPad) {
  import("./styles/tablet.less");
  console.log("loaded tablet.less");
} else {
  // TODO: 把PC HomeView.less 含图片的样式移到这里
  import("./styles/pc.less");
}

// ipad样式

router.afterEach(() => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
