var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content style-dark page-foot",attrs:{"id":"PageFoot"}},[_c('a',{attrs:{"name":"community"}}),_c('div',{staticClass:"inner-cont page-relative"},[_vm._m(0),_c('div',{staticClass:"show-list9"},[_c('div',{staticClass:"row",on:{"click":function($event){return _vm.openUrl('mailto:hello@fatpay.xyz')}}},[_vm._m(1),_c('div',{staticClass:"title"},[_vm._v("Email")]),_c('div',{staticClass:"text"},[_vm._v("hello@fatpay.xyz")])]),_c('div',{staticClass:"row",on:{"click":function($event){return _vm.openUrl('https://twitter.com/realFatPay')}}},[_vm._m(2),_c('div',{staticClass:"title"},[_vm._v("Twitter")]),_c('div',{staticClass:"text"},[_vm._v("@realFatPay")])]),_c('div',{staticClass:"row",on:{"click":function($event){return _vm.openUrl('https://medium.com/@fatpay')}}},[_vm._m(3),_c('div',{staticClass:"title"},[_vm._v("Medium")]),_c('div',{staticClass:"text"},[_vm._v("@FaTPay")])])]),_c('div',{staticClass:"page-bg4"}),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title"},[_c('h2',[_vm._v("Join the Community")]),_c('p',{staticClass:"page-text1"},[_vm._v(" Learn more about Fat Pay, chat with the talents, others in the community, and have your say in shaping the future of Digital Money. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon-face-link-email.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon-face-link-twitter.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon-face-link-medium.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-text2"},[_c('span',[_vm._v("Copyright © FaTPay 2022")]),_c('a',{staticClass:"page-text2__terms",attrs:{"href":"https://ramp.fatpay.xyz/staticpages/terms-of-use-privacy-policy.html","target":"_blank"}},[_vm._v(" Terms of Use & Privacy Policy ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-text3"},[_c('p',[_vm._v(" All the services and solutions are provided, where applicable, by Fast and Trust Technology Limited UAB (registered in Lithuania, incorporation number 306029983), with address at Laisvés pr. 60, Vilnius, FAT TECHNOLOGY INC.(registered in Canada under incorporation number 1000282818, MSB registration number M22596457), with address at 800 Steeles W Ave,Unit B10182,Thornhill,ON,Canada L4J7L2. FaTPay provides its services only via fatpay.xyz / fatpay.org / fatpay.co / fatpay.tech and the official APPs. FaTPay is not affiliated with any other similar websites and is not responsible for any acts takenby their owners. ")]),_c('p',[_vm._v(" FaTPay provides its services only via fatpay.xyz. FaTPay is not affiliated with any other similar websites and is not responsible for any acts takenby their owners. ")])])
}]

export { render, staticRenderFns }