<template>
  <div class="layer-jd">
    <div class="title">
      <h2>{{ data.mainTitle }}</h2>
    </div>
    <div class="subtitle">
      <div class="left">{{ data.region }} / {{ data.workTime }}</div>
      <div class="right">{{ data.pubTime }}</div>
    </div>
    <div class="content" v-for="item in data.detail" :key="item.title">
      <h3>{{ item.title }}:</h3>
      <div class="information" v-for="(info, index) in item.data" :key="index">
        <h5>{{ serialNumber[index] }}.</h5>
        <p>{{ info }}</p>
      </div>
    </div>
  </div>
</template>

<script>
const serialNumber = function () {
  const arr = [];
  for (var i = 0; i < 26; i++) {
    arr.push(String.fromCharCode(97 + i));
  }
  return arr;
};
export default {
  name: "JdDetail",
  data() {
    return {
      serialNumber: serialNumber(),
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          mainTitle: "Fiat Channel Lead",
          region: "Singapore",
          workTime: "Fulltime",
          pubTime: "March 21, 2022",
          detail: [
            {
              title: "Responsibilities",
              data: [
                "Proactively reach out to new potential global/local partners, knowing the whole business picture to negotiate the business terms effectively and successfully onboard the partners",
                "Own the relationship with Fiat global/local partners, negotiate business terms, and onboard appropriate channels",
              ],
            },
            {
              title: "Requirements",
              data: [
                "5 years+ of proven success in a similar role leading business negotiations in ideally from a payments or financial institution",
                "Data-savvy, understand basic metrics of marketing and operations",
              ],
            },
          ],
        };
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.layer-jd {
  width: 100%;
  .title {
    h2 {
      font-family: Poppins;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: var(--sapphire);
      margin-bottom: 8px;
    }
  }
  .subtitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: "Poppins-Light";

    .left {
      flex-flow: 1;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.5px;
      color: var(--navy-blue);
    }
    .right {
      text-align: right;
      line-height: 1.5;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.5px;
      color: var(--navy-blue-op6);
    }
  }

  .content {
    h3 {
      font-family: Poppins;
      margin-top: 80px;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: var(--sapphire);
      margin-bottom: 30px;
    }
    .information {
      color: var(--navy-blue);
      font-size: 16px;
      line-height: 24px;
      h5 {
        font-family: "Poppins";
      }
      p {
        font-family: "Poppins-Light";
        margin-bottom: 30px;
      }
    }
  }
}
</style>
