<template>
  <div class="page-frame">
    <iframe src="https://buy.fatpay.xyz" frameborder="0"></iframe>
    <page-footer />
  </div>
</template>

<script>
import PageFooter from "@/components/PageFooter.vue";

export default {
  name: "BuyCrypto",
  components: {
    PageFooter,
  },
};
</script>

<style scoped lang="less">
.page-frame {
  padding-top: 80px;
  iframe {
    height: calc(100vh - 80px);
    width: 100%;
  }
}
</style>
