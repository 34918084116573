<template>
  <div
    class="layer-confirm"
    :style="{ display: `${isShow ? 'block' : 'none'}` }"
  >
    <div class="layer-header">
      <div class="layer-icon">
        <!-- {renderIcon()} -->
        <img :src="iconType[type] || iconType['success']" alt="" />
      </div>
    </div>
    <div class="layer-body">
      <div class="body-inner">
        <slot></slot>
      </div>
      <div class="btn-cont">
        <button class="btn-primary" @click="handleClose">
          Close({{ countDown }}s)
        </button>
      </div>
    </div>
    <div class="layer-mask" @click.stop.prevent></div>
  </div>
</template>

<script>
import IconSuccess from "@/assets/images/icon-success.png";
const confirmProps = {
  icon: String,
  duration: 3000,
  message: [String, Node],
  onClose: {
    type: Function,
    required: false,
    default: () => {},
  },
  isShow: {
    type: Boolean,
    required: true,
    default: false,
  },
  type: {
    type: String,
    required: false,
    validator: function (value) {
      // The value must match one of these strings
      return ["success", "warning", "danger"].indexOf(value) !== -1;
    },
  },
};

let countDownInterval = null;
export default {
  name: "ConfirmLayer",
  props: confirmProps,
  watch: {
    isShow(val) {
      if (val) {
        // 打开dialog
        document.body.style.overflow = "hidden";
        this.countDownTime();
      } else {
        // 关闭dialog
        document.body.style.overflow = "";
      }
    },
    countDown(val) {
      if (val === 0) {
        setTimeout(() => {
          this.handleClose();
        }, 300);
      }
    },
  },
  data() {
    return {
      iconType: {
        success: IconSuccess,
        warning: "",
        danger: "",
      },
      countDown: 3,
    };
  },
  computed: {
    iconPath: () => {},
  },
  methods: {
    renderIcon() {
      const { icon, type } = this.props;
      const hasIcon = icon || type === "success" || type === "fail";

      if (hasIcon) {
        return <img name={icon || type} class={"icon"} />;
      }
    },
    handleClose() {
      this.$emit("onClose", false);
      clearInterval(countDownInterval);
    },
    countDownTime() {
      countDownInterval = setInterval(() => {
        if (this.countDown === 1) {
          clearInterval(countDownInterval);
        }
        this.countDown = this.countDown - 1;
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@contWidth: 702px;
.layer-confirm {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.layer-header {
  height: 50px;
  top: 32%;
  position: fixed;
  width: @contWidth;
  left: 50%;
  z-index: 10;
  margin-left: -(@contWidth / 2);
  .layer-icon {
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
}
.layer-body {
  background: #fff;
  border-radius: 32px;
  position: fixed;
  width: @contWidth;
  left: 50%;
  top: 42%;
  z-index: 9;
  margin-left: -(@contWidth / 2);
  min-height: 100px;
  box-sizing: border-box;
  padding: 100px 30px 60px;
  .body-inner {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--sapphire);
  }
  .btn-cont {
    text-align: center;
    padding-top: 40px;
  }
}
.layer-mask {
  background-color: var(--navy-blue);
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}
</style>
