<template>
  <div>
    <!-- screen1 -->
    <div class="focus-header cooperate-header">
      <div class="inner-cont">
        <div class="header-cont1">
          <div class="main">
            <h1>Boost your project with one click</h1>
            <p class="info">
              FatPay offers you the best bridge between fiat and crypto world.
            </p>
          </div>
          <div class="header-btn">
            <button class="btn-primary" @click="handleShowForm">
              Boost Now
            </button>
            <p class="link">
              <router-link :to="{ path: '/' }"
                >More Details<i class="el-icon-arrow-right"
              /></router-link>
            </p>
          </div>
        </div>
        <div class="header-bg" />
      </div>
    </div>
    <!-- footer -->
    <page-footer></page-footer>
    <!-- layout -->
    <fat-dialog :isShow="isFormShow" :onClose="onFormClose">
      <form-coop @submit="submitForm" />
    </fat-dialog>
    <confirm :isShow="isShowConfirm" @onClose="handleCloseConfirm"
      >Submitted successfully !</confirm
    >
  </div>
</template>

<script>
import PageFooter from "../components/PageFooter.vue";
import FatDialog from "../components/FatDialog.vue";
import Confirm from "../components/Confirm.vue";
import FormCoop from "../components/FormForCoop.vue";
import "../styles/FatStyleElement.less";
import { Loading } from "element-ui";
// Payments volume 基础数值

export default {
  name: "CorporateView",
  components: {
    PageFooter,
    FatDialog,
    Confirm,
    FormCoop,
  },
  data: () => {
    return {
      isFormShow: false,
      isShowConfirm: false,
    };
  },
  methods: {
    handleShowForm() {
      this.isFormShow = true;
    },
    onFormClose() {
      this.isFormShow = false;
    },
    //提交表单
    submitForm(data) {
      // checkbox group 替换
      this.onFormClose();
      const loadingInstance = Loading.service({
        background: "rgba(0, 23, 72, 0.8)",
      });
      // TODO:提交表单
      console.log("submitForm", data);
      setTimeout(() => {
        loadingInstance.close();
        this.isShowConfirm = true;
      }, 1500);
    },
    handleCloseConfirm() {
      this.isShowConfirm = false;
    },
  },
};
</script>

<style scoped lang="less">
.focus-header {
  // height: 1030px;
  width: 100%;
  background-image: url("@/assets/images/fat-cover-photo-bg-corporate.jpg");
  .inner-cont {
    overflow: hidden;
  }
  .header-bg {
    background: url("@/assets/images/coverphoto-corporate.png") no-repeat 100% 0;
    position: absolute;
    width: 1340px;
    height: 960px;
    bottom: 0;
    top: auto;
    left: 60%;
    margin-left: -550px;
    display: block;
    z-index: 1;
    background-size: auto 960px;
  }
  .header-btn {
    text-align: center;
    width: 254px;
    position: absolute;
    top: 665px;
    button {
      width: 100%;
    }
    .link {
      padding: 16px 0;
      text-align: center;
      width: 100%;
      font-family: "Poppins-Light";
    }
  }
  .header-cont1 {
    .info {
      top: 530px;
      font-family: "Poppins-Light";
    }
  }
}
</style>
